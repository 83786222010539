import React from 'react';

export default function contactForm() {
  return (
    <div className="section section-two contact">
      <h4>Send us a message</h4>
      <hr />
      <div className="form-div">
        <form className="form">
          <div className="form-group">
            <label>Your name</label>
            <input type="name" className="form-control" required />
          </div>
          <div className="form-group">
            <label>Your email</label>
            <input type="email" className="form-control" required />
          </div>
          <div className="form-group">
            <label>Your phone number</label>
            <input type="number" className="form-control" required />
          </div>
          <div className="form-group">
            <label>Your message</label>
            <textarea
              type="text"
              rows="5"
              className="form-control"
              required
            ></textarea>
          </div>

          <button type="submit" className="btn sign-up-btn">
            SEND MESSAGE
          </button>
        </form>

        <div className="infos">
          <div className="info">
            <img
              src="https://vela-web-site.s3.us-east-2.amazonaws.com/svg/location.svg"
              alt=""
            />
            <p>952 Idejo Street, Off Adeola Odeku, VI, Lagos.</p>
          </div>
          <div className="info">
            <img
              src="https://vela-web-site.s3.us-east-2.amazonaws.com/svg/phone.svg"
              alt=""
            />
            <p>0813 360 6231</p>
          </div>
        </div>
      </div>
    </div>
  );
}
