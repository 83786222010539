import React from 'react';
import WhatWeDoCard from '../molecules/whatWeDoCard';

export default function whatWeDo() {
  return (
    <section id="section-two">
      <div className="section section-two">
        <h3>what we do</h3>
        <hr className="section-hr"/>
        <div className="cards">
          <WhatWeDoCard />
        </div>
      </div>
    </section>
  );
}
