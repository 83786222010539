import React from "react";
import Header from "../molecules/header";

export default function transmonitorHeader() {
  return (
    <Header
      topText='Transmonitor'
      bottomText='Helping small and medium scale enterprises receiving payments via mobile cash transfer.'
    />
  );
}
