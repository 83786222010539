import React from "react";
import Header from "../molecules/header";

export default function contactHeader() {
  return (
    <Header
      topText='Say hello to us'
      bottomText='Let’s talk business or just have a coffee, we’d love to hear from you.'
    />
  );
}
