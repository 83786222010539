import React from "react";

export default function header({ image, howText }) {
  return (
    <>
      <div className="macbook-img">
        <img src={image} alt="transmonitor" />
      </div>

      <div className="section section-two works">
        <h3>How it works</h3>
        <p>{howText}</p>
      </div>
    </>
  );
}
