import React from 'react';
import whatWeDoData from '../../assets/data/whatWeDoData.json';

export default function whatWeDoCard() {
  return (
    <>
      {whatWeDoData.map(a => {
        return (
          <div
            className="card d-flex justify-content-center align-items-center"
            key={a.id}
          >
            <img
              src={a.icon}
              className="what-we-do-card-icon"
              alt="d ellipse"
            />
            <img
              src={a.iconsecond}
              className="what-we-do-icon"
              alt="d ellipse"
            />
            <h5>{a.title}</h5>
            <p>{a.content}</p>
          </div>
        );
      })}
    </>
  );
}
