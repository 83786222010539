import React from "react";
import Header from "../molecules/header";

export default function transmonitorHeader() {
  return (
    <Header
      topText='Vela Bank'
      bottomText='Providing online and offline banking solutions
      with ground breaking features to ease 
      mobile digital banking.'
    />
  );
}
