import React from 'react';

export default function homeFooterSection() {
  return (
    <section id="section-four">
      <div className="d-flex align-items-center section-four">
        <div className="in-good-hand" />
          <div className="d-flex flex-column in-good-company">
            <p>YOU'RE IN GREAT COMPANY</p>
            <div className="d-flex image-section-one">
              <div className="good-company image-one">
                <img
                src="https://vela-web-site.s3.us-east-2.amazonaws.com/img/econnectnp.png" alt="econnect logo" />
              </div>
              <div className="good-company image-two">
                <img
                src="https://vela-web-site.s3.us-east-2.amazonaws.com/img/jaiz_bank.png" alt="jaiz bank logo" />
              </div>
            </div>
            <div className="d-flex image-section-two">
              <div className="good-company image-three">
                <img
                src="https://vela-web-site.s3.us-east-2.amazonaws.com/img/salamtakaful.png" alt="salam takaful logo" />
              </div>
              <div className="good-company image-four">
                <img
                src="https://vela-web-site.s3.us-east-2.amazonaws.com/img/travelbeta.png" alt="travelbeta logo" />
              </div>
            </div>
          </div>
      </div>
    </section>
  );
}
