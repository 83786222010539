import React from 'react';
import { Link } from 'react-router-dom';

export default function footer() {
  return (
    <footer className="main">
      <div className="footer-content columns-row">
        <div className="column set set-section">
          <img
            src="https://vela-web-site.s3.us-east-2.amazonaws.com/img/logo.png"
            className="footer-logo"
            alt="logo"
          />
        </div>
        <div className="column set">
          <p className="footer-link-header-text">About</p>
          <div className="footer-links">
            <p>
              <Link to="/contact" className="link">
                Contact
              </Link>
            </p>
            <p>
              <Link to="/careers" className="link">
                Careers
              </Link>
            </p>
          </div>
        </div>

        <div className="column set">
          <p className="footer-link-header-text">Developers</p>
          <div className="footer-links">
            <p>
              <Link to="/" className="link">
                Overview
              </Link>
            </p>
            <p>
              <Link to="/" className="link">
                Documentation
              </Link>
            </p>
          </div>
        </div>

        <div className="column set set-one">
          <p className="footer-link-header-text">Social</p>
          <div className="footer-links">
            <p>
              <a href="https://facebook.com" className="link">
                Facebook
              </a>
            </p>
            <p>
              <a href="https://linkedin.com" className="link">
                LinkedIn
              </a>
            </p>
            <p>
              <a href="https://instagram.com" className="link">
                Instagram
              </a>
            </p>
          </div>
        </div>
        <div className="column set set-one">
          <p className="footer-link-header-text">Contact</p>
          <div className="footer-links">
            <p>952, Idejo Street, Off Adeola<br/> Odeku, Victoria Island, Lagos</p>
            <p>+2348133606231</p>
            <p>tech@vela.com.ng</p>
          </div>
        </div>
      </div>
      <hr className="h-rule" />
      <p className="copy">Copyright &#9400; 2019 Vela Buisness Solutions</p>
    </footer>
  );
}
