import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Home from './templates/home';
import Careers from './templates/careers';
import Transmonitor from './templates/transmonitor';
import Velabank from './templates/velabank';
import Contact from './templates/contact';
const NotFound = () => <h1>page not found</h1>;

export default class App extends Component {
  render = () => {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/careers" component={Careers} />
          <Route path="/solutions/transmonitor" component={Transmonitor} />
          <Route path="/solutions/velabank" component={Velabank} />
          <Route exact path="/contact" component={Contact} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    );
  };
}
