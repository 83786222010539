import React, {useEffect} from 'react';
import Helmet from 'react-helmet';
import Navbar from '../organisms/navbar';
import ContactHeader from '../organisms/contactHeader';
import ContactForm from '../organisms/contactForm';
import ContactMap from '../organisms/contactMap';
import Footer from '../organisms/footer';

export default function Contact() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
          <title>Vela Business Solution - Contact </title>
      </Helmet>
      <main className="main" id="main">
        <Navbar />
        <section className='contact'>
          <ContactHeader />
          <ContactForm />
          <ContactMap />
        </section>
      </main>
      <Footer />
    </>
  );
}
