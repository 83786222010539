import React from 'react';
import FeatureCard from '../molecules/featuresCard';
import transmonitorData from '../../assets/data/transmonitorData.json';

export default function careersHeader() {
  return (
    <>
      <section id="section-three">
        <div className="section section-three">
          <h3>Top Features</h3>
          <div className="value-cards">
            <FeatureCard data={transmonitorData} />
          </div>
        </div>
      </section>
    </>
  );
}
