import React, { useEffect } from 'react';
import Navbar from '../organisms/navbar';
import HomeHeader from '../organisms/homeHeader';
import WhatWeDo from '../organisms/whatWeDo';
import HomeSection from '../organisms/homeSection';
import HomeFooterSection from '../organisms/homeFooterSection';
import Footer from '../organisms/footer';

export default function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <main className="main" id="main">
        <Navbar />
        <section className="hero">
          <HomeHeader />
          <WhatWeDo />
        </section>
        <HomeSection />
        <HomeFooterSection />
      </main>
      <Footer />
    </>
  );
}
