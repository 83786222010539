import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../organisms/navbar';
import VelabankHeader from '../organisms/velabankHeader';
import SolutionInfo from '../organisms/solutionInfo';
import VelabankFeatures from '../organisms/velabankFeatures';
import Footer from '../organisms/footer';

export default function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <main className="main" id="main">
        <Navbar />
        <section className="velabank">
          <VelabankHeader />
          <SolutionInfo
            image="https://vela-web-site.s3.us-east-2.amazonaws.com/img/velabank.png"
            howText="Users can transfer money, pay for bills and data and also perform alot of other internet banking functions even without data on their mobile devices."
          />
        </section>
        <VelabankFeatures />
        <div className="next-div">
          <Link to="/solutions/transmonitor">
            <img
              src="https://vela-web-site.s3.us-east-2.amazonaws.com/svg/arrow-right.svg"
              className="rotate"
              alt="arrow"
            />
            SEE PREVIOUS SOLUTION
          </Link>
        </div>
      </main>
      <Footer />
    </>
  );
}
