/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export default class navbar extends React.Component {
  componentDidMount() {
    const { pathname } = window.location;
    const el = document.querySelector('.navdrop');
    if (pathname === '/solutions/transmonitor') el.classList.add('active');
    else if (pathname === '/solutions/velabank') el.classList.add('active');
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container-fluid">
          <Link to="/">
            <img
              src="https://vela-web-site.s3.us-east-2.amazonaws.com/img/logo.png"
              alt="logo"
              className="brand-logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarsExample08"
            aria-controls="navbarsExample08"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <img
                className="open-nav"
                src="https://vela-web-site.s3.us-east-2.amazonaws.com/img/menu.png"
                alt="navbar"
              />
            </span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-md-center not-signed-in"
            id="navbarsExample08"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to="/home" className="links">
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  href="#"
                  className="links navdrop"
                  id="dropdown01"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Solutions
                </a>
                <div
                  className="dropdown-menu pt-4 pb-4"
                  aria-labelledby="dropdown01"
                >
                  <p className="dropdown-solution">OUR SOLUTIONS</p>
                  <NavLink
                    className="dropdown-item"
                    to="/solutions/transmonitor"
                  >
                    <div className="d-flex justify-content-between align-items-center pl-2 pr-2">
                      <img
                        className="navbar__navbar-solution-img"
                        src="https://vela-web-site.s3.us-east-2.amazonaws.com/svg/block.svg"
                        alt=""
                      />
                      <div className="d-flex flex-column">
                        <p className="dropdown-solution-title">Transmonitor</p>
                        <p className="dropdown-solution-content">
                          Vela is a financial technology
                          <br />
                          solutions company focused on
                        </p>
                      </div>
                      <button className="dropdown-solution-button">VIEW</button>
                    </div>
                  </NavLink>
                  <NavLink className="dropdown-item" to="/solutions/velabank">
                    <div className="d-flex justify-content-between align-items-center pl-2 pr-2">
                      <img
                        className="navbar__navbar-solution-img"
                        src="https://vela-web-site.s3.us-east-2.amazonaws.com/svg/block.svg"
                        alt=""
                      />
                      <div className="d-flex flex-column">
                        <p className="dropdown-solution-title">
                          Offline Banking App (OBA)
                        </p>
                        <p className="dropdown-solution-content">
                          Vela is a financial technology
                          <br />
                          solutions company focused on
                        </p>
                      </div>
                      <button className="dropdown-solution-button">VIEW</button>
                    </div>
                  </NavLink>
                </div>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className="links">
                  Contact
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/careers" className="links">
                  Careers
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
