import React from 'react';
import { Link } from 'react-router-dom';

export default function homeHeader() {
  return (
    <section id="section-one">
      <div className="section section-one">
        <div className="row">
          <div className="col-xl-7 col-md-8 col-sm-6">
            <div className="left header__header-left">
              <p className="head-text">
                Driver’s of financial inclusion through Innovation
              </p>
              <p className="sub-head-text">
                Vela is a financial technology solutions company focused on
                building breaking fintech solutions for enterprises.
              </p>
              <Link to="/solutions/transmonitor" className="primary-btn">
                EXPLORE OUR SOLUTIONS
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
