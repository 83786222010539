import React from "react";

export default function header({ topText, bottomText }) {
  return (
    <section id="section-one">
      <div className="section section-one">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-12">
            <div className="left">
              <p className="head-text">{topText}</p>
              <p className="sub-head-text">
                {bottomText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
