import React from 'react';
import { Link } from 'react-router-dom';
import positionData from '../../assets/data/positionData.json';

export default function whatWeDoCard() {
  return (
    <>
      {positionData.map(a => {
        return (
          <div className="position-card" key={a.id}>
            <img src={a.icon} className="card-icon" alt="icon" />
            <div className="info">
              <div className="left">
                <h5>
                  {a.number} {a.role}
                </h5>
                <p>{a.skills}</p>
              </div>
              <div className="right">
                <Link to="/careerpost">
                  <img
                    src="https://vela-web-site.s3.us-east-2.amazonaws.com/svg/arrow.svg"
                    className="arrow"
                    alt="arrow icon"
                  />
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
