import React from 'react';
import PositionCard from '../molecules/positionCard';

const positions = () => (
  <section id="section-two">
    <div className="section section-two positions">
      <h4>Open Positions</h4>
      <hr />
      <div className="cards">
        <PositionCard />
      </div>
    </div>
  </section>
);

export default positions;
