import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import Navbar from '../organisms/navbar';
import CareersHeader from '../organisms/careersHeader';
import Positions from '../organisms/positions';
import Footer from '../organisms/footer';

export default function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Vela Business Solution - Careers </title>
      </Helmet>
      <main className="main" id="main">
        <Navbar />
        <section className="careers">
          <CareersHeader />
          <Positions />
        </section>
      </main>
      <Footer />
    </>
  );
}
