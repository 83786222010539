import React from "react";
import Header from "../molecules/header";

export default function careersHeader() {
  return (
    <Header
      topText='Join Our Amazing Team Of Talents'
      bottomText='Think you have what it takes to build FINTECH solutions for the
      future? Then join our pool of talents today.'
    />
  );
}
