import React from 'react';
import FeaturesCard from '../molecules/featuresCard';
import coreValueData from '../../assets/data/coreValueData.json';

export default function homeSection() {
  return (
    <section id="section-three" className="relative">
      <div className="section-three-1" />
      <div className="section section-three">
        <div className="section-three-2" />
        <div className="section-three-3" />
        <h3>Our core values</h3>
        <div className="value-cards">
          <FeaturesCard data={coreValueData} />
        </div>
      </div>
    </section>
  );
}
