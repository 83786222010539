import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../organisms/navbar';
import TransmonitorHeader from '../organisms/transmonitorHeader';
import SolutionInfo from '../organisms/solutionInfo';
import TransmonitorFeatures from '../organisms/transmonitorFeatures';
import Footer from '../organisms/footer';

export default function Home() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <main className="main" id="main">
        <Navbar />
        <section className="transmonitor">
          <TransmonitorHeader />
          <SolutionInfo
            image="https://vela-web-site.s3.us-east-2.amazonaws.com/svg/macbook.svg"
            howText="This solution enables merchants recieve and track fund transfers from customers for payments of goods via bank mobiles and web apps."
          />
        </section>
        <TransmonitorFeatures />
        <div className="next-div">
          <Link to="/solutions/velabank">
            SEE NEXT SOLUTION
            <img
              src="https://vela-web-site.s3.us-east-2.amazonaws.com/svg/arrow-right.svg"
              alt="arrow"
            />
          </Link>
        </div>
      </main>
      <Footer />
    </>
  );
}
