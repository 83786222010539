import React from 'react';

export default function featuresCard({ data }) {
  return (
    <>
      {data.map(a => {
        return (
          <div className="value-card" key={a.id}>
            <img className="value-card-img" src={a.icon} alt="w ellipse" />
            <div className="info">
              <h6>{a.title}</h6>
              <p>{a.content}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}
