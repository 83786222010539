import React from 'react';

const mapStyle = {
  position: 'relative',
  width: '100%',
  height: '500px'
};

function ContactMap() {
  return (
    <iframe
      title="googlemap"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.748139299274!2d3.4120815147702177!3d6.426395895350473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8ace70a9b01f%3A0x3613862f79270673!2s952%20Idejo%20St%2C%20Victoria%20Island%2C%20Lagos!5e0!3m2!1sen!2sng!4v1572085329297!5m2!1sen!2sng"
      width="100%"
      height="600"
      frameBorder="0"
      style={mapStyle}
      allowFullScreen=""
    ></iframe>
  );
}

export default ContactMap;
